@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url("./assets/fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf") format("woff");
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "Open Sans",
    monospace;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font: 19px / 23px Open_Sans, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Media Query */
@media (max-width: 1380px) {

  html,
  body {
    font-size: rem;
    line-height: normal;
  }
}